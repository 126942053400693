import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import {
  BackgroundTexture,
  Layout,
  SEO,
  SideBySide,
  WhatsOnAccordion,
} from '../../components';
import { ContactFormWithMap } from '../../components/contact-form-with-map';
import { useGraphQL } from '../../hooks';

function PromotionsPage(): React.ReactElement {
  return (
    <Layout>
      <SEO title="Promotions" />
      <WhatsOnAccordion />
      <HappyHour />
      <TabHourOfPower />
      <ContactFormWithMap background="blue" />
    </Layout>
  );
}

function HappyHour(): React.ReactElement {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "promotions-happy-hour.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);
  return (
    <div className="py-12">
      <SideBySide
        isReversed={false}
        sideComponent={
          <GatsbyImage
            image={file.childImageSharp.gatsbyImageData}
            className="flex-1 h-full"
            alt=""
          />
        }
      >
        <div className="flex h-full py-8 bg-blue-light text-blue-dark">
          <div className="flex flex-col justify-center w-full p-4 pt-8 mx-auto max-w-prose">
            <h2 className="heading-2 heading-accent">Happy Hour</h2>
            <h3 className="mt-5 heading-3">$5.50 Schooner and House Wines</h3>
            <div className="mt-2 prose text-blue-dark">
              <h4>
                Every Monday to Thursday from
                <br />
                5pm - 6pm
              </h4>
              <p>
                Members of the club can enjoy $5.50 schooners and house wines.
              </p>
            </div>
          </div>
        </div>
      </SideBySide>
    </div>
  );
}

function TabHourOfPower(): React.ReactElement {
  const { tabHourOfPower } = useGraphQL();
  return (
    <div className="py-12">
      <div className="relative">
        <BackgroundTexture overflowBottom insetBottom="half" />
        <SideBySide
          sideComponent={
            <GatsbyImage
              image={tabHourOfPower.childImageSharp.gatsbyImageData}
              className="flex-1 h-full"
              alt=""
            />
          }
        >
          <div className="flex flex-col justify-center w-full p-4 mx-auto -mt-12 xs:-mt-0 xs:pt-8 max-w-prose">
            <h2 className="heading-2 heading-accent">
              Tab Hour
              <br /> of Power
            </h2>
            <div className="mt-8 prose">
              <p>Every Saturday from 1pm to 2pm</p>
              <p>Happy Hour prices</p>
              <p>Only in the Non-Thinkers Bar</p>
            </div>
          </div>
        </SideBySide>
      </div>
    </div>
  );
}

export { PromotionsPage as default };
